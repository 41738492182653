.practices {
    background: url('../../images/img-home.jpg') center center/cover 
    no-repeat;
    background-position-y: 0px;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
    object-fit: contain;
    letter-spacing: 0.3rem;
}

.practices > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
}

.practices-content > h1 {
    scroll-margin-top: 100px;
    margin: auto;
    margin-top: 6rem;
    margin-bottom: 3rem;
    max-width: fit-content;
    padding: 0.25rem;
    background-color: #FFC627;
    font-size: 60px;
    letter-spacing: 0.2rem;
}

.practice-schedule {
    max-width: 80%;
    margin: auto;
    font-size: 1.5rem;
    margin-bottom: 4rem;
    border: 5px solid #242222;
    border-collapse: collapse;
}

.practice-schedule > th, td {
    border: 3px solid #242222;
    text-align: center;
    padding: 1rem;
    line-height: 1.5;
}

.icon {
    font-size: 4rem;
}

.discipline {
    background-color: #FFC627;
    padding-inline: 2px;
}

@media screen and (max-width: 960px) {
    
    .practices > h1 {
        font-size: 100px;
    }

    .practices-content > h1 {
      font-size: 60px;
    }

    .practice-schedule {
        font-size: 1.5rem;
    }

    .practice-schedule > th, td {
        padding: 1rem;
    }

    .icon {
        font-size: 4rem;
    }
    
  }
  
@media screen and (max-width: 768px) {
    
    .practices > h1 {
        font-size: 70px;
    }
    
    .practices-content > h1 {
        font-size: 40px;
      }

    .practice-schedule {
        font-size: 1rem;
    }

    .practice-schedule > th, td {
        padding: 0.5rem;
    }

    .icon {
        font-size: 2.5rem;
    }

}