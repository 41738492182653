* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

.home,
.services,
.events,
.sign-up,
.faq {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('images/img-home.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.events {
  background: url('images/img-home.jpg') center center/cover 
  no-repeat;
  background-position-y: 0px;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
  object-fit: contain;
  letter-spacing: 0.2rem;
}

.events > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}


.event-btns {
  margin-top: 32px;
}

.event-btns .btn {
  margin: 6px;
}

.events-body { 

  margin: auto;
  
}

.events-body > h2 {
  scroll-margin-top: 100px;
  margin: auto;
  margin-top: 6rem;
  margin-bottom: 6rem;
  max-width: fit-content;
  padding: 0.25rem;
  background-color: #FFC627;
  letter-spacing: 0.2rem;
  text-align: center;
}

.faq {
  background: url('images/img-home.jpg') center center/cover 
  no-repeat;
  background-position-y: 0px;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
  object-fit: contain;
}

.faq > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
  margin-left: 4rem;
  align-self: flex-start;
}

.faq > p {
  margin-top: 8px;
  margin-left: 4rem;
  align-self: flex-start;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.faq-body { 
  margin: auto;
  margin-top: 3rem;
  width: 70%;
  max-width: 850px;
  justify-content: center;

  .heading {
    font-size: 30px;
    margin-bottom: 0.5rem;
  }

  .paragraph {
    display: inline-block;
    font-weight: lighter;
    font-size: 18px;
    margin-bottom: 1.5rem;
    opacity: 0.8;
  }
}

.sign-up {
  background-image: url('images/img-home.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

@media screen and (min-width: 769px) {
  .events-body {
    width: 70%;
  }

  .events-body > h2 {
    font-size: 80px;
  }

  .header {
    font-size: 50px;
  }

  .faq > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  
  .events > h1 {
    font-size: 70px;
  }

  .events-body > h2 {
    font-size: 40px;
  }

  .faq > h1 {
    font-size: 50px;
    margin-top: -100px;
    margin: auto;
  }

  .faq > p {
    font-size: 30px;
    margin: auto;
  }

  .events-body {
    width: 90%;
  }

  .header {
    font-size: 30px;
  }
}